import { Injectable } from "@angular/core";
import { Http, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { LockLine } from "../../modules/lock-line/models/lock-line";
import { CreateLotDto } from "../../modules/lot/services/create-lot-dto";

import { AppConsts } from "../AppConsts";
import {
  ILotMeltLineParams,
  ILotFilterParams,
  ILotReassayRequestCommand,
} from "../models";

@Injectable()
export class LotService {
  constructor(private http: Http) {}

  // Get Lot details by given LotNumber
  getLotById(id: number): Observable<any> {
    var url =
      AppConsts.remoteServiceBaseUrl + AppConsts.getLotByIdUrl + "/" + id;
    //return this.http.get(url)
    //    .map((response: Response) => response.json());

    return this.http.get(url).pipe(map((response) => response.json()));
  }
  lockLinesByLotId(id: number) {
    return this.http
      .get(
        AppConsts.remoteServiceBaseUrl + AppConsts.lockLinesByLotId + `/${id}`
      )
      .pipe(map((response) => <LockLine[]>response.json()));
  }

  settleLot(
    lotNumber: number,
    settlementOption: string,
    token: string,
    includeHolds: boolean
  ): Observable<any> {
    var obj = {
      lotNumber: lotNumber,
      settlementOption: settlementOption,
      token: token,
      wireInfo: null,
      includeHolds: includeHolds,
    };
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.settleLotUrl;
    return this.http.post(url, obj);
  }

  Update(lotnumber: number, description: string): Observable<any> {
    var obj = {
      lotNumber: lotnumber,
      description: description,
    };

    var url = AppConsts.remoteServiceBaseUrl + "api/lots/SettleLot";
    return this.http.post(url, obj);
  }

  loadLotSettleDetails(lotMeltLineParam: ILotMeltLineParams): Observable<any> {
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.getLotSettleDetailsUrl;
    return this.http
      .get(url, {
        params: {
          meltLine: lotMeltLineParam.meltLine,
          lotNumber: lotMeltLineParam.lotNumber,
          beforeWeight: lotMeltLineParam.beforeWeight,
          afterWeight: lotMeltLineParam.afterWeight,
          sampleType: lotMeltLineParam.sampleType,
          token: encodeURIComponent(lotMeltLineParam.token),
        },
      })
      .pipe(map((response) => response.json()));
  }

  // Get Lots by status based on given filter parameters
  getFilteredLots(lotParams: ILotFilterParams): Observable<any> {
    // var url = AppConsts.remoteServiceBaseUrl + AppConsts.GetSettledLotDetailsUrl;
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.getLotsUrl;
    return this.http
      .get(url, {
        params: {
          pageIndex: lotParams.pageIndex,
          pageSize: lotParams.pageSize,
          status: lotParams.status,
          dateReceivedFrom: lotParams.dateReceivedFrom,
          dateReceivedTo: lotParams.dateReceivedTo,
          dueDateFrom: lotParams.dueDateFrom,
          dueDateTo: lotParams.dueDateTo,
          statuses: lotParams.statuses,
          toContTot: lotParams.toContTot,
          toContTotFrom: lotParams.toContTotFrom,
          toContTotTo: lotParams.toContTotTo,
          sort: lotParams.sort,
          lotNumber: lotParams.lotNumber,
          referenceNumber: lotParams.referenceNumber,
          description: lotParams.description,
          navigationPage: lotParams.navigationPage,
          poNumber: lotParams.poNumber,
          searchText: lotParams.searchText,
        },
      })
      .pipe(map((response) => response.json()));
  }

  // Get Settled Lot Details (uses joins on 'Lot', 'rfAudit' and 'LotSettle' tables)
  getSettledLotDetails(lotParams: ILotFilterParams): Observable<any> {
    var url =
      AppConsts.remoteServiceBaseUrl + AppConsts.getSettledLotDetailsUrl;
    return this.http
      .get(url, {
        params: {
          pageIndex: lotParams.pageIndex,
          pageSize: lotParams.pageSize,
          status: lotParams.status,
          statuses: lotParams.statuses,
          sort: lotParams.sort,
        },
      })
      .pipe(map((response) => response.json()));
  }

  createLot(lotDto: CreateLotDto): Observable<any> {
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.lotCreateUrl;
    return this.http.post(url, lotDto).pipe(map((response) => response.json()));
  }

  getLotNotes(lotNumber: number): Observable<any> {
    var url =
      AppConsts.remoteServiceBaseUrl +
      AppConsts.getLotNotesUrl +
      "/" +
      lotNumber;
    return this.http.get(url).pipe(map((response) => response.json()));
  }

  addLotMessage(lotNumber: number, message: string): Observable<any> {
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.lotNewMessageUrl;
    var obj = {
      lotNumber: lotNumber,
      message: message,
    };
    return this.http.post(url, obj).pipe(map((response) => response.json()));
  }

  DownloadAcknowledgementReport(lotNumber: string) {
    var url =
      AppConsts.remoteServiceBaseUrl +
      AppConsts.downloadAcknowledgementReportUrl +
      "/" +
      lotNumber;
    let requestOptions = new RequestOptions();
    requestOptions.responseType = ResponseContentType.ArrayBuffer;
    return this.http.post(url, null, requestOptions);
  }

  downloadSettlementReport(id: string) {
    var url =
      AppConsts.remoteServiceBaseUrl +
      AppConsts.downloadSettlementReportUrl +
      "/" +
      id;
    let requestOptions = new RequestOptions();
    requestOptions.responseType = ResponseContentType.ArrayBuffer;
    return this.http.post(url, null, requestOptions);
  }

  downloadContainerContentReceipt() {
    var url =
      AppConsts.remoteServiceBaseUrl +
      AppConsts.downloadContainerContentReceipt;
    let requestOptions = new RequestOptions();
    requestOptions.responseType = ResponseContentType.ArrayBuffer;
    return this.http.post(url, null, requestOptions);
  }

  downloadStoneRemovalReceipt() {
    var url =
      AppConsts.remoteServiceBaseUrl + AppConsts.downloadStoneRemovalReceipt;
    let requestOptions = new RequestOptions();
    requestOptions.responseType = ResponseContentType.ArrayBuffer;
    return this.http.post(url, null, requestOptions);
  }

  lotReassayRequest(lotReassayDto: ILotReassayRequestCommand): Observable<any> {
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.lotReassayRequest;
    return this.http
      .post(url, lotReassayDto)
      .pipe(map((response) => response.json()));
  }

  updateAllDeltaLotLines(
    lotNumber: number,
    lockedUnlockedLines: any,
    token: string
  ): Observable<any> {
    var url =
      AppConsts.remoteServiceBaseUrl + AppConsts.lotEstLineUpdateDeltaUrl;
    return this.http
      .post(url, {
        lotNumber: lotNumber,
        lotLockedUnlockedLines: lockedUnlockedLines,
        token: token,
      })
      .pipe(map((response) => response.json()));
  }

  cancelLot(lotNumber: number, token: string): Observable<any> {
    var obj = {
      lotNumber: lotNumber,
      token: token,
    };
    var url = AppConsts.remoteServiceBaseUrl + AppConsts.cancelLotUrl;
    return this.http.post(url, obj);
  }
  refreshLotForSettlement(token: string, lotNumber: number, poNumber: string) {
    var url =
      AppConsts.remoteServiceBaseUrl +
      AppConsts.refreshLotForSettlementUrl +
      "/" +
      token +
      "/" +
      lotNumber +
      "/" +
      poNumber;
    return this.http.get(url).pipe(map((response) => response.text()));
  }
}
