﻿
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'negativeValuePipe'
})
export class NegativeValuePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value == null) {
            return;
        }
        return value.charAt(0) == '-' ?
            '(' + value.substring(1, value.length) + ')' :
            value;
    }

}