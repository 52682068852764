﻿import { Injectable } from "@angular/core";
import {
  SessionServiceProxy,
  UserLoginInfoDto,
} from "./service-proxies/service-proxies";
import { TokenService } from "../services/token.service";
import { Subject } from "rxjs/internal/Subject";
import { Observable } from "rxjs/internal/Observable";
import { Http, RequestOptions, ResponseContentType } from "@angular/http";
import { AppConsts } from "../AppConsts";

@Injectable()
export class AppSessionService {
  private _user: UserLoginInfoDto;

  private isUserAuthenticatedInSubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private sessionService: SessionServiceProxy,
        private tokenService: TokenService,
        private http: Http,
    ) {}

  get user(): UserLoginInfoDto {
    return this._user;
  }

  get isUserAuthenticated(): Observable<boolean> {
    return this.isUserAuthenticatedInSubject.asObservable();
  }

  getShownLoginName(): string {
    return this._user.username;
  }
  
  getShownAccountName(): string {
    return this._user.accountName;
  }

  getEnvironment(): Observable<any> {
    return this.http.get(AppConsts.remoteServiceBaseUrl + AppConsts.getEnvironmentTypeUrl);
  }

  setUser(data: any): void {
    this._user = UserLoginInfoDto.fromJS(data);
    this.isUserAuthenticatedInSubject.next(true);
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      var token = this.tokenService.getToken();
      if (token) {
        this.sessionService
          .getCurrentLoginInformation()
          .toPromise()
          .then(
            (result: UserLoginInfoDto) => {
              this._user = result;
              this.isUserAuthenticatedInSubject.next(true);
              resolve(true);
            },
            (err) => {
              reject(err);
            }
          );
      } else {
        resolve(true);
      }
    });
  }
}
