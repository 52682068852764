import { Component, AfterViewInit } from '@angular/core';
import { ExternalLoginService } from './modules/external-login/external-login.service';

@Component({
  selector: 'dg-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'app';
  showFooter: boolean = true;

  constructor(
    private externalLoginService: ExternalLoginService
  ) {

  }

  ngAfterViewInit() {
    // Used to sense that this is reset password page and we dp not need to show spot prices footer here.
    setTimeout(() => {
      this.externalLoginService.status.subscribe((val: boolean) => {
        this.showFooter = val;
      });
    }, 100);
  }
}
