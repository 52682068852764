import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class PageService {
    private pageChangeSubject = new Subject<number>();

    pageChangeTrack(pageNumber: number) {
        this.pageChangeSubject.next(pageNumber);
    }

    getPageNumber(): Observable<number> {
        return this.pageChangeSubject.asObservable();
    }
}
