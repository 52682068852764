import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  Injector,
  ModuleWithProviders,
  NgModule,
} from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import {
  Http,
  HttpModule,
  JsonpModule,
  RequestOptions,
  XHRBackend,
} from "@angular/http";
import { MatCheckboxModule } from "@angular/material";
import { MatRadioModule } from "@angular/material/radio";
import { RouterModule } from "@angular/router";
import { ClickOutsideModule } from "ng-click-outside";
import { NgxPaginationModule } from "ngx-pagination";
import { CalendarModule } from "primeng/calendar";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DropdownModule } from "primeng/dropdown";
import { SidebarModule } from "primeng/sidebar";
import { DgHttp, DgHttpConfiguration } from "../app.http";
import { AppPreBootstrap } from "../AppPreBootstrap";
import { BadgeService } from "../modules/app-layout/navigation/badge.service";
import { AppConsts } from "./AppConsts";
import { BusyIfDirective } from "./directives/busyif";
import { AccountRouteGuard } from "./guards/account-route-guard";
import { AppRouteGuard } from "./guards/auth-route-guard";
import { AbsolutePipe } from "./pipes/absolute.pipe";
import { FxIfPipe } from "./pipes/fx-if-pipe";
import { NegativeValuePipe } from "./pipes/negative-value-pipe";
import { UtcDatePipe } from "./pipes/utc-date.pipe";
import { ReloadComponent } from "./reload/reload.component";
import { AppAuthService } from "./services/app-auth.service";
import { AppSessionService } from "./services/app-session.service";
import { BidPriceService } from "./services/bid-sprice.service";
import { LocalStorageService } from "./services/local-storage.service";
import { LotService } from "./services/lot.service";
import { MessageService } from "./services/message.service";
import { NavigationService } from "./services/navigation.service";
import { PageService } from "./services/page.service";
import { API_BASE_URL } from "./services/service-proxies/service-proxies";
import { SessionStorageService } from "./services/session-storage.service";
import { TokenService } from "./services/token.service";
import { UtilsService } from "./services/utils.service";

export function abpHttpFactory(
  xhrBackend: XHRBackend,
  requestOptions: RequestOptions,
  configuration: DgHttpConfiguration,
  tokenService: TokenService
): Http {
  return new DgHttp(xhrBackend, requestOptions, configuration, tokenService);
}

export let APP_HTTP_PROVIDER = {
  provide: Http,
  useFactory: abpHttpFactory,
  deps: [XHRBackend, RequestOptions, DgHttpConfiguration, TokenService],
};

export function appInitializerFactory(injector: Injector) {
  return () => {
    dg.ui.setBusy();
    return new Promise<boolean>((resolve, reject) => {
      AppPreBootstrap.run(() => {
        var appSessionService: AppSessionService =
          injector.get(AppSessionService);
        appSessionService.init().then(
          (result) => {
            dg.ui.clearBusy();
            resolve(result);
          },
          (err) => {
            dg.ui.clearBusy();
            reject(err);
          }
        );
      });
    });
  };
}

export function getRemoteServiceBaseUrl(): string {
  return AppConsts.remoteServiceBaseUrl;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    // No need to export as these modules don't expose any components/directive etc'
    HttpModule,
    JsonpModule,
    MatCheckboxModule,
    MatRadioModule,
    ConfirmDialogModule,
    DropdownModule,
    SidebarModule,
    CalendarModule,
    ClickOutsideModule,
    NgxPaginationModule,
  ],
  declarations: [
    UtcDatePipe,
    FxIfPipe,
    BusyIfDirective,
    AbsolutePipe,
    NegativeValuePipe,
    ReloadComponent,
  ],
  exports: [
    // Modules
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    MatCheckboxModule,
    MatRadioModule,
    ConfirmDialogModule,
    DropdownModule,
    SidebarModule,
    CalendarModule,
    UtcDatePipe,
    NgxPaginationModule,
    FxIfPipe,
    BusyIfDirective,
    AbsolutePipe,
    NegativeValuePipe,
    ClickOutsideModule,
  ],
  providers: [
    APP_HTTP_PROVIDER,
    { provide: API_BASE_URL, useFactory: getRemoteServiceBaseUrl },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [Injector],
      multi: true,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        MessageService,
        TokenService,
        AppAuthService,
        AppRouteGuard,
        AccountRouteGuard,
        SessionStorageService,
        AppSessionService,
        LocalStorageService,
        NavigationService,
        UtilsService,
        DgHttpConfiguration,
        AppConsts,
        PageService,
        BidPriceService,
        BadgeService,
        LotService,
      ],
    };
  }
}
