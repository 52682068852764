﻿import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { Http, Headers, ResponseContentType, Response } from '@angular/http';

import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');

export interface IUserLoginInfoDto {
    username: string;
    accountName: string;
    emailAddress: string;
    isCustomerAdmin: boolean;
    canTradePool: boolean;
    hasProTradeRole: boolean;
}

export interface IAuthenticateModel {
    emailAddress: string;
    password: string;
}

export interface IAuthenticateResultModel {
    authToken: string;
    userId: number;
    accountId: number;
    username: string;
    accountName: string;
    cookieDomain: string;
    expirationTime: string;
}

export class UserLoginInfoDto implements IUserLoginInfoDto {
    username: string;
    accountName: string;
    emailAddress: string;
    isCustomerAdmin: boolean;
    canTradePool: boolean;
    hasProTradeRole: boolean;
    constructor(data?: IUserLoginInfoDto) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    static fromJS(data: any): UserLoginInfoDto {
        let result = new UserLoginInfoDto();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.username = data['username'];
            this.accountName = data['accountName'];
            this.emailAddress = data['emailAddress'];
            this.isCustomerAdmin = data['isCustomerAdmin'];
            this.hasProTradeRole = data['hasProTradeRole'];
            this.canTradePool = data['canTradePool'];
        }
    }
}

export class AuthenticateModel implements IAuthenticateModel {
    emailAddress: string;
    password: string;

    constructor(data?: IAuthenticateModel) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    static fromJS(data: any): AuthenticateModel {
        let result = new AuthenticateModel();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.emailAddress = data['emailAddress'];
            this.password = data['password'];
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['emailAddress'] = this.emailAddress;
        data['password'] = this.password;
        return data;
    }

    clone() {
        const json = this.toJSON();
        let result = new AuthenticateModel();
        result.init(json);
        return result;
    }
}

export class AuthenticateResultModel implements IAuthenticateResultModel {
    authToken: string;
    encryptedAuthToken: string;
    userId: number;
    accountId: number;
    username: string;
    accountName: string;
    emailAddress: string;
    cookieDomain: string;
    expirationTime: string;
    isCustomerAdmin: boolean;
    hasProTradeRole: boolean;
    canTradePool: boolean;
    constructor(data?: IAuthenticateResultModel) {
        if (data) {
            for (let property in data) {
                if (data.hasOwnProperty(property)) {
                    (<any>this)[property] = (<any>data)[property];
                }
            }
        }
    }

    static fromJS(data: any): AuthenticateResultModel {
        let result = new AuthenticateResultModel();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.authToken = data['authToken'];
            this.encryptedAuthToken = data['encryptedAuthToken'];
            this.userId = data['userId'];
            this.accountId = data['accountId'];
            this.username = data['username'];
            this.accountName = data['accountName'];
            this.emailAddress = data['emailAddress'];
            this.cookieDomain = data['cookieDomain'];
            this.expirationTime = data['expirationTime'];
            this.isCustomerAdmin = data['isCustomerAdmin'];
            this.hasProTradeRole = data['hasProTradeRole'];
            this.canTradePool = data['canTradePool'];
        }
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data['authToken'] = this.authToken;
        data['encryptedAuthToken'] = this.encryptedAuthToken;
        data['userId'] = this.userId;
        data['accountId'] = this.accountId;
        data['username'] = this.username;
        data['accountName'] = this.accountName;
        data['emailAddress'] = this.emailAddress;
        data['cookieDomain'] = this.cookieDomain;
        data['expirationTime'] = this.expirationTime;
        data['isCustomerAdmin'] = this.isCustomerAdmin;
        data['hasProTradeRole'] = this.hasProTradeRole;
        data['canTradePool'] = this.canTradePool;
        return data;
    }

    clone() {
        const json = this.toJSON();
        let result = new AuthenticateResultModel();
        result.init(json);
        return result;
    }
}

@Injectable()
export class TokenAuthServiceProxy {
    private http: Http;
    private baseUrl: string;
    protected jsonParseReviver: (key: string, value: any) => any = undefined;

    constructor(@Inject(Http) http: Http, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : '';
    }

    authenticate(model: AuthenticateModel): Observable<AuthenticateResultModel> {
        let url = this.baseUrl + 'api/token/authenticate';
        url = url.replace(/[?&]$/, '');

        const content = JSON.stringify(model);

        let options: any = {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        return this.http.post(url, content, options).pipe(mergeMap(response => {
            return this.processAuthenticate(response);
        }),
            catchError(response => {
                if (response instanceof Response) {
                    try {
                        return this.processAuthenticate(response);
                    } catch (e) {
                        return <Observable<AuthenticateResultModel>><any>throwError(e);
                    }
                } else {
                    return <Observable<AuthenticateResultModel>><any>throwError(response);
                }
            }));
    }

    protected processAuthenticate(response: Response): Observable<AuthenticateResultModel> {
        const status = response.status;

        let headers: any = response.headers ? response.headers.toJSON() : {};
        if (status === 200) {
            const responseText = response.text();
            let result200: any = null;
            let resultData200 = responseText === '' ? null : JSON.parse(responseText, this.jsonParseReviver);
            result200 = resultData200 ? AuthenticateResultModel.fromJS(resultData200) : new AuthenticateResultModel();
            return of(result200);
        } else if (status !== 200 && status !== 204) {
            const responseText = response.text();
        }

        return of<AuthenticateResultModel>(<any>null);
    }
}

export class SessionServiceProxy {
    private http: Http;
    private baseUrl: string;
    protected jsonParseReviver: (key: string, value: any) => any = undefined;

    constructor(@Inject(Http) http: Http, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : '';
    }

    getCurrentLoginInformation(): Observable<UserLoginInfoDto> {
        let url = this.baseUrl + 'api/session/getcurrentlogininformation';
        url = url.replace(/[?&]$/, '');

        let options: any = {
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        return this.http.get(url, options).pipe(mergeMap(response => {
            return this.processGetCurrentLoginInformation(response);
        }),
            catchError(response => {
                if (response instanceof Response) {
                    try {
                        return this.processGetCurrentLoginInformation(response);
                    } catch (e) {
                        return <Observable<UserLoginInfoDto>><any>throwError(e);
                    }
                } else {
                    return <Observable<UserLoginInfoDto>><any>throwError(response);
                }
            }));
    }

    protected processGetCurrentLoginInformation(response: Response): Observable<UserLoginInfoDto> {
        const status = response.status;

        let headers: any = response.headers ? response.headers.toJSON() : {};
        if (status === 200) {
            const responseText = response.text();
            let result200: any = null;
            let resultData200 = responseText === '' ? null : JSON.parse(responseText, this.jsonParseReviver);
            result200 = resultData200 ? UserLoginInfoDto.fromJS(resultData200) : new UserLoginInfoDto();
            return of(result200);
        } else if (status !== 200 && status !== 204) {
            const responseText = response.text();
        }

        return of<UserLoginInfoDto>(<any>null);
    }
}
