import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { noop, Observable } from "rxjs";
import { AppConsts } from "../../../shared/AppConsts";
import { AppAuthService } from "../../../shared/services/app-auth.service";
import { AppSessionService } from "../../../shared/services/app-session.service";
import { BidPriceService } from "../../../shared/services/bid-sprice.service";
import { NavigationService } from "../../../shared/services/navigation.service";
import { BadgeService } from "./badge.service";

@Component({
  selector: "dg-app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
  //providers: [BadgeService]
})
export class NavigationComponent implements OnInit {
  environmentType: string = "";
  shownAccountName: string = "";
  shownLoginName: string = "";
  display: boolean = false;
  @Output()
  showSearchMobileBar: EventEmitter<boolean> = new EventEmitter<boolean>();
  searchBox: boolean = false;
  iraConnectLink: string;
  closedLoopLink: string;
  spotInaBoxLink: string;
  pointOfSaleLink: string;
  manageStoreLink: string;
  poolPositionLink: string;
  classicFizTradeLink: string;
  proTradeLink: string;
  customerCareNumber: string;
  technicalSupportNumber: string;
  refinerySupportNumber: string;
  hasProTradeRole: boolean;
  canTradePool: boolean;
  canCreateLockLine$: Observable<boolean>;
  showLockLineButton: boolean = false;
  // This holds a list of pop downs that "CloseAllPopDowns" can read from. CloseAll also handles toggling for the selected popdown
  popDowns = [
    "#divAppsPopDown",
    "#divContactUsPopDown",
    "#divUserSettingsPopDown",
  ];

  tempPopDowns: string[];
  popDownOpen: boolean;
  marketOpen: boolean = null;

  constructor(
    private navigationService: NavigationService,
    private appSessionService: AppSessionService,
    private authService: AppAuthService,
    private badgeService: BadgeService,
    private bidPriceService: BidPriceService
  ) {
    this.badgeService.badgeCount$.subscribe((res: any) => {
      this.GetReadyToSettleCount("Ready To Settle");
    });

    //set MarketOpen flag (true: Open, false: Closed)
    this.bidPriceService.getMarketStatus().subscribe((marketStatus: any) => {
      this.marketOpen =
        this.bidPriceService.processMarketStatusData(marketStatus);
    });

    this.bidPriceService.spotPriceData.subscribe(
      (res: any) => {
        //get market status
        this.bidPriceService
          .getMarketStatus()
          .subscribe((marketStatus: any) => {
            this.marketOpen =
              this.bidPriceService.processMarketStatusData(marketStatus);
          });
      },
      (error: any) => {
        console.log("error");
      }
    );
  }

  readyToSettleCount: number;
  GetReadyToSettleCount(status: string) {
    this.badgeService.getReadyToSettleCount(status).subscribe(
      (res: any) => {
        this.readyToSettleCount = res;
      },
      (error: any) => {}
    );
  }

  ngOnInit() {
    this.appSessionService.getEnvironment().subscribe((val)=>{
        this.environmentType = val._body.toLocaleUpperCase();
        this.shownAccountName = this.appSessionService.getShownAccountName().toLocaleUpperCase();
    });
    this.shownLoginName = this.appSessionService.getShownLoginName();
    this.GetReadyToSettleCount("Ready To Settle");
    this.iraConnectLink =
      AppConsts.iraConnectHost +
      "/#!/?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token"));
    this.closedLoopLink =
      AppConsts.fizTradeHost +
      "/FizTrade/Logon?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token")) +
      "&page=closedLoop";
    this.spotInaBoxLink =
      AppConsts.fizTradeHost +
      "/FizTrade/Logon?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token")) +
      "&page=spotInBox";
    this.pointOfSaleLink =
      AppConsts.fizTradeHost +
      "/FizTrade/Logon?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token")) +
      "&page=pointOfSale";
    this.manageStoreLink =
      AppConsts.fizTradeHost +
      "/FizTrade/Logon?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token")) +
      "&page=manageStore";
    this.poolPositionLink =
      AppConsts.fizTradeHost +
      "/FizTrade/Logon?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token")) +
      "&page=poolPosition";
    this.classicFizTradeLink =
      AppConsts.redesignFiztradeHost +
      "/app/externallogin?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token"));
    this.proTradeLink =
      AppConsts.fizTradeHost +
      "/FizTrade/Logon?token=" +
      encodeURIComponent(dg.utils.getCookieValue("enc_auth_token")) +
      "&page=proTrade";
    this.hasProTradeRole = this.appSessionService.user.hasProTradeRole;
    this.canTradePool = this.appSessionService.user.canTradePool;

    this.customerCareNumber = AppConsts.customerCareNumber;
    this.technicalSupportNumber = AppConsts.technicalSupportNumber;
    this.refinerySupportNumber = AppConsts.refinerySupportNumber;
      this.navigationService.enabledLockLine().subscribe(() => {
          this.showLockLineButton = true;
          this.canCreateLockLine$ = this.navigationService.canCreateLockLine$;
      });
  }

  logout(): void {
    this.authService.logout();
  }

  toggleAppList(event: any) {
    this.closeAllPopDowns("#divAppsPopDown");
    event.stopPropagation();
  }

  toggleContactInfo(event: any) {
    this.closeAllPopDowns("#divContactUsPopDown");
    event.stopPropagation();
  }

  toggleUserSettingsList(event: any) {
    this.closeAllPopDowns("#divUserSettingsPopDown");
    event.stopPropagation();
  }

  onClickedOutside(event: Event) {
    this.closeAllPopDowns();
  }

  // Except is the actual popdown you want to toggle
  // ExceptArr are popdowns you don't want to affect at all
  closeAllPopDowns(except: string = "", exceptArr: string[] = []) {
    // this.searchBox = false;
    this.tempPopDowns = JSON.parse(JSON.stringify(this.popDowns));
    var index: number = this.tempPopDowns.indexOf(except);
    this.popDownOpen = false;
    if (exceptArr.length > 0) {
      for (let exceptStr of exceptArr) {
        index = this.tempPopDowns.indexOf(exceptStr);
        if (index !== -1) {
          this.tempPopDowns.splice(index, 1);
        }
      }
    } else {
      if (index !== -1) {
        $("#divSearchLotsResults").addClass("hidden");
        $("#searchProduct").val("");
      }
    }
    for (let popDown of this.tempPopDowns) {
      if (!$(popDown).css("display", "none")) {
        this.popDownOpen = true;
      }
      $(popDown).slideUp();
    }

    $(except).slideToggle();
  }

  navigateToDashboard() {
    this.navigationService.navigateByUrl("/dashboard");
    this.badgeService.badgeUpdated(1);
  }

  navigateToCreateNewLot() {
    if (this.marketOpen) {
      this.navigationService.navigateByUrl("/lot/create");
    }
  }
  navigateToCreateNewLockLine() {
    if (this.marketOpen) {
      this.navigationService.navigateByUrl("/lock-line/create");
    }
  }

  navigateToLots() {
    this.navigationService.navigateByUrl("/lot");
    this.badgeService.badgeUpdated(1);
  }

  navigateToReadyToSettle() {
    this.navigationService.navigateByUrl("/lot/ready-to-settle");
  }
  SideBarToggle() {
    this.display = !this.display;
  }
  moveContent(event: any) {
    $("#content").toggleClass("sliderOpen");
    $("#footerSpotPrice").toggleClass("spotSlide");
    $("#navigationComp").toggleClass("navHeader");
    $("body").toggleClass("stopScroll");
    $("html").toggleClass("stopScroll");
  }

  showHideSeachBox() {
    this.searchBox = !this.searchBox;
    this.showSearchMobileBar.emit(this.searchBox);
  }
}
