import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppLayoutComponent } from "./modules/app-layout/app-layout.component";
import { ExternalLoginComponent } from "./modules/external-login/external-login.component";
import { HomeComponent } from "./modules/home/home.component";
import { AccountRouteGuard } from "./shared/guards/account-route-guard";
import { ReloadComponent } from "./shared/reload/reload.component";

const APP_ROUTES: Routes = [
  { path: "", component: HomeComponent, canActivate: [AccountRouteGuard] },
  { path: "logon", component: ExternalLoginComponent },

  // App routes
  {
    path: "",
    component: AppLayoutComponent,
    children: [
      {
        path: "dashboard",
        loadChildren: "./modules/dashboard/dashboard.module#DashboardModule",
      },
      {
        path: "lot",
        loadChildren: "./modules/lot/lot.module#LotModule",
      },
      // {
      //   path: "ready-to-settle",
      //   loadChildren:
      //     "./modules/ready-to-settle/ready-to-settle.module#ReadyToSettleModule",
      // },
      {
        path: "lock-line",
        loadChildren: "./modules/lock-line/lock-line.module#LockLineModule",
      },
      { path: "reload", component: ReloadComponent },
    ],
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
