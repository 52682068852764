import { Http } from "@angular/http";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { AppConsts } from "../AppConsts";
import { map, share, shareReplay, tap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class NavigationService {
  private _canCreateLockLine$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  canCreateLockLine$ = this._canCreateLockLine$
    .asObservable()
    .pipe(shareReplay(1));

  constructor(private router: Router, private http: Http) {}

  navigateTo(action: string, data?: any) {
    this.router.navigate([action], data);
  }

  navigateByUrl(action: string) {
    this.router
      .navigateByUrl("/reload", { skipLocationChange: true })
      .then(() => this.router.navigate([action]));
  }

  enabledLockLine() {
    const url = AppConsts.remoteServiceBaseUrl + AppConsts.enabledLockLine;
    return this.http.get(url).pipe(
      map((response) => response.json()),
      tap((response) => {
        localStorage.setItem("enabledLockLine", response);
        this._canCreateLockLine$.next(response);
      })
    );
  }
}
