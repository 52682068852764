import { Component } from "@angular/core";
import { ILot, ILotFilterParams } from "../../../../shared/models";
import { LotService } from "../../../../shared/services/lot.service";
import { NavigationService } from "../../../../shared/services/navigation.service";

@Component({
  selector: "app-product-search",
  templateUrl: "./product-search.component.html",
  styleUrls: ["./product-search.component.css"],
  providers: [LotService],
})
export class ProductSearchComponent {
  searchBox: boolean;
  lotSearch: string;
  state: boolean = false;
  showSearchBar: boolean = true;
  showLotFound: boolean = false;
  lots: ILot[] = [];

  constructor(
    private lotService: LotService,
    private navigationService: NavigationService
  ) {}

  searchLot(newValue: string) {
    this.lotSearch = newValue;
    if (this.lotSearch.length > 2) {
      $("#divSearchLotsResults").removeClass("hidden");
      this.state = false;
      this.showLotFound = false;
      this.showSearchBar = true;
      let lotParams: ILotFilterParams = {};
      lotParams.pageIndex = 1;
      lotParams.pageSize = 4;
      lotParams.searchText = this.lotSearch;
      this.lotService.getFilteredLots(lotParams).subscribe(
        //pageIndex:0, pageSize:10, stauts:0
        (res) => {
          if (res.items.length > 0) {
            this.lots = res.items;
            this.showSearchBar = false;
            this.state = true;
            this.showLotFound = false;
          } else {
            this.showLotFound = true;
          }
        },
        (error) => {
          this.showSearchBar = false;
          console.log(error);
        }
      );
    } else $("#divSearchLotsResults").addClass("hidden");
  }

  hideSeachResults() {
    this.lotSearch = "";
    $("#divSearchLotsResults").addClass("hidden");
  }

  navigateToLotDetails(lotNumber: number) {
    this.lotSearch = "";
    $("#divSearchLotsResults").addClass("hidden");
    this.navigationService.navigateByUrl("/lot/detail/" + lotNumber);
  }
}
