import { Injectable } from "@angular/core";
import {
  Headers,
  Http,
  RequestOptions,
  RequestOptionsArgs,
  Response,
  ResponseOptions,
  XHRBackend,
} from "@angular/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { MessageService } from "./shared/services/message.service";
import { TokenService } from "./shared/services/token.service";

export interface IValidationErrorInfo {
  message: string;
  members: string[];
}

export interface IErrorInfo {
  code: number;
  message: string;
  details: string;
  validationErrors: IValidationErrorInfo[];
}

export interface IAjaxResponse {
  success: boolean;
  result?: any;
  targetUrl?: string;
  error?: IErrorInfo;
  unAuthorizedRequest: boolean;
}

export interface IStreamResponse {
  isValid: boolean;
  result?: any;
}

export class StreamResponse implements IStreamResponse {
  isValid: boolean;
  result?: any;
}

@Injectable()
export class DgHttpConfiguration {
  constructor(
    private messageService: MessageService,
    private tokenService: TokenService
  ) {}

  defaultError = <IErrorInfo>{
    message: "An error has occurred!",
    details: "Error details were not sent by server.",
  };

  defaultError401 = <IErrorInfo>{
    message: "You are not authenticated!",
    details:
      "You should be authenticated (sign in) in order to perform this operation.",
  };

  defaultError403 = <IErrorInfo>{
    message: "You are not authorized!",
    details: "You are not allowed to perform this operation.",
  };

  defaultError404 = <IErrorInfo>{
    message: "Resource not found!",
    details: "The resource requested could not be found on the server.",
  };

  showError(error: IErrorInfo): any {
    if (error.details) {
      return this.messageService.error(
        error.details,
        error.message || this.defaultError.message
      );
    } else {
      return this.messageService.error(
        error.message || this.defaultError.message
      );
    }
  }

  handleTargetUrl(targetUrl: string): void {
    if (!targetUrl) {
      location.href = "/";
    } else {
      location.href = targetUrl;
    }
  }

  handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
    const self = this;

    this.tokenService.clearToken();

    if (messagePromise) {
      messagePromise.done(() => {
        this.handleTargetUrl(targetUrl || "/");
      });
    } else {
      self.handleTargetUrl(targetUrl || "/");
    }
  }

  getAjaxResponseOrNull(response: Response): IAjaxResponse | null {
    if (!response || !response.headers) {
      return null;
    }

    var contentType = response.headers.get("Content-Type");
    if (!contentType) {
      return null;
    }

    if (contentType.indexOf("application/json") < 0) {
      return null;
    }

    return response.json() as IAjaxResponse;
  }

  getStreamOrNull(response: Response): IStreamResponse | null {
    if (!response || !response.headers) {
      return null;
    }

    var contentType = response.headers.get("Content-Type");
    if (!contentType) {
      return null;
    }

    if (contentType.indexOf("application/octet-stream") < 0) {
      return null;
    }

    let streamResponse: IStreamResponse = new StreamResponse();
    streamResponse.isValid = true;
    streamResponse.result = response;
    return streamResponse;
  }

  handleNonDgErrorResponse(response: Response) {
    const self = this;

    switch (response.status) {
      case 401:
        self.handleUnAuthorizedRequest(null, "/");
        break;
      case 403:
        self.showError(self.defaultError403);
        break;
      case 404:
        self.showError(self.defaultError404);
        break;
      default:
        self.showError(self.defaultError);
        break;
    }
  }

  handleDgResponse(
    response: Response,
    ajaxResponse: IAjaxResponse,
    showError: boolean = false
  ): Response {
    var newResponse = new ResponseOptions({
      url: response.url,
      body: ajaxResponse,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
      type: response.type,
    });

    if (ajaxResponse.success) {
      newResponse.body = ajaxResponse.result;
    } else {
      if (!ajaxResponse.error) {
        ajaxResponse.error = this.defaultError;
      }

      if (showError) {
        this.showError(ajaxResponse.error);
      }

      if (response.status === 401) {
        this.handleUnAuthorizedRequest(null, ajaxResponse.targetUrl);
      }
    }

    return new Response(newResponse);
  }

  handleResponse(response: Response): Response {
    // check stream response
    var streamResponse = this.getStreamOrNull(response);
    if (streamResponse) {
      return streamResponse.result;
    }

    var ajaxResponse = this.getAjaxResponseOrNull(response);
    if (ajaxResponse === null) {
      return null;
    }

    return this.handleDgResponse(response, ajaxResponse);
  }

  handleError(error: Response, options?: any): Observable<any> {
    var ajaxResponse = this.getAjaxResponseOrNull(error);
    if (ajaxResponse != null) {
      this.handleDgResponse(error, ajaxResponse);
      return throwError(ajaxResponse.error);
    } else {
      this.handleNonDgErrorResponse(error);
      return throwError(
        "HTTP error: " + error.status + ", " + error.statusText
      );
    }
  }
}

@Injectable()
export class DgHttp extends Http {
  protected configuration: DgHttpConfiguration;
  protected tokenService: TokenService;

  constructor(
    backend: XHRBackend,
    defaultOptions: RequestOptions,
    configuration: DgHttpConfiguration,
    tokenService: TokenService
  ) {
    super(backend, defaultOptions);
    this.configuration = configuration;
    this.tokenService = tokenService;
  }

  get(url: string, options?: RequestOptionsArgs): Observable<Response> {
    if (!options) {
      options = {};
    }
    this.normalizeRequestOptions(options);
    return super.get(url, options).pipe(
      map((response) => this.configuration.handleResponse(response)),
      catchError((error) => this.configuration.handleError(error))
    );
  }

  post(
    url: string,
    body: any,
    options?: RequestOptionsArgs
  ): Observable<Response> {
    if (!options) {
      options = {};
    }
    this.normalizeRequestOptions(options);
    return super.post(url, body, options).pipe(
      map((response) => this.configuration.handleResponse(response)),
      catchError((error) => this.configuration.handleError(error))
    );
  }

  // request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
  //     if (!options) { options = {}; }
  //     this.normalizeRequestOptions(options);
  //     return super
  //         .request(url, options)
  //         .map(response => this.configuration.handleResponse(response))
  //         .catch(error => this.configuration.handleError(error));
  // }

  protected normalizeRequestOptions(options: RequestOptionsArgs) {
    if (!options.headers) {
      options.headers = new Headers();
    }

    // options.headers.append('Pragma', 'no-cache');
    // options.headers.append('Cache-Control', 'no-cache');
    // options.headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

    this.addAuthorizationHeaders(options);
  }

  protected addXRequestedWithHeader(options: RequestOptionsArgs) {
    if (options.headers) {
      options.headers.append("X-Requested-With", "XMLHttpRequest");
    }
  }

  protected addAuthorizationHeaders(options: RequestOptionsArgs): void {
    let authorizationHeaders = options.headers
      ? options.headers.getAll("Authorization")
      : null;
    if (!authorizationHeaders) {
      authorizationHeaders = [];
    }

    if (
      !this.itemExists(
        authorizationHeaders,
        (item: string) => item.indexOf("Bearer ") === 0
      )
    ) {
      const token = this.tokenService.getToken();
      if (options.headers && token) {
        options.headers.append("Authorization", "Bearer " + token);
      }
    }
  }

  private itemExists<T>(items: T[], predicate: (item: T) => boolean): boolean {
    for (let i = 0; i < items.length; i++) {
      if (predicate(items[i])) {
        return true;
      }
    }

    return false;
  }
}
