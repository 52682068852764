import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dg-app-layout",
  templateUrl: "./app-layout.component.html",
  styleUrls: ["./app-layout.component.css"],
})
export class AppLayoutComponent implements OnInit {
  mobileSearchBar: boolean = false;
  constructor() {}

  ngOnInit() {}
  showSearchMobileBar(value: boolean) {
    this.mobileSearchBar = value;
  }
}
