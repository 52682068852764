﻿import { Injectable } from "@angular/core";

@Injectable()
export class UtilsService {

    constructor() {}

    getCookieValue(key: string): string {
        return dg.utils.getCookieValue(key);
    }

    setCookieValue(key: string, value?: string, expireDate?: Date, path?: string, domain?: string): void {
        dg.utils.setCookieValue(key, value, expireDate, path, domain);
    }

    deleteCookie(key: string, path?: string): void {
        dg.utils.deleteCookie(key, path);
    }
}
