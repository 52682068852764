import { Headers, RequestOptions } from "@angular/http";

export class AppConsts {
  static appBaseUrl: string;
  static apiUrl: string;

  static captchaToken: string;
  static tokenName: string = "fiz.token";
  static cookiePath: string = "/";
  static cookieDomain: string;
  static cookieExpirationTime: string;
  static copyrightYear: string;
  static iraConnectHost: string;
  static fizTradeHost: string;
  static redesignFiztradeHost: string;
  static customerCareNumber: string;
  static technicalSupportNumber: string;
  static refinerySupportNumber: string;
  static readonly authorization = {
    encrptedAuthTokenName: "enc_auth_token",
  };

  // all url's string literals     - s t a r t
  static getEnvironmentTypeUrl: string = "api/Environment/GetEnvironmentType";
  static externalLoginUrl: string = "api/token/externallogin";
  static createLotUrl: string = "api/lots/CreateLot";
  static createEstimateLineUrl: string =
    "api/lotEstimateLine/CreateEstimateLines";
  static getMetalTypeUrl: string = "api/lotEstimateLine/GetMetalTypes";
  static getLotByIdUrl: string = "api/lots/GetLotById";
  static getLotsUrl: string = "api/lots/GetLots";
  static getSettledLotDetailsUrl: string = "api/lots/GetSettledLotDetails";
  static getEstimateLinesUrl: string = "api/lotEstimateLine/GetEstimateLines";
  static getGetLockedEstimateLinesUrl: string =
    "api/lotEstimateLine/GetLockedEstimateLines";
  static getLotNotesUrl: string = "api/Note/GetNotesByLotNumber";
  static lotCreateUrl: string = "api/lots/create";
  static lotNewMessageUrl: string = "api/lots/newmessage";
  static getReadyToSettleCountUrl: string = "api/lots/GetStatusCount";
  static getSpotPricesUrl: string = "api/bidprice/GetBidPrices";
  static getSpotPricesUrlForLoggedUser: string =
    "api/bidprice/GetBidPricesForLoggedInUser";
  static getMarketStatusUrl: string = "api/bidprice/GetMarketStatus";
  static getMarketStatusUrlForLoggedUser: string =
    "api/bidprice/GetMarketStatusForLoggedInUser";
  static getLotSettleDetailsUrl = "api/LotMeltLine/GetLotSettleDetails";
  static lotEstLineAddUrl = "api/lotEstimateLine/LotEstLineAdd";
  static lotEstLinesLockUrl = "api/lotEstimateLine/LotEstLinesLock";
  static lotEstLineUpdateDeltaUrl = "api/lotEstimateLine/LotEstLineUpdateDelta";
  static lotEstLineDeleteUrl = "api/lotEstimateLine/LotEstLineDelete";
  static downloadAcknowledgementReportUrl =
    "api/lots/DownloadAcknowledgementReport";
  static downloadSettlementReportUrl = "api/lots/DownloadSettlementReport";
  static downloadContainerContentReceipt =
    "api/lots/DownloadContainerContentReceipt";
  static downloadStoneRemovalReceipt = "api/lots/DownloadStoneRemovalReceipt";
  static settleLotUrl = "api/lots/SettleLot";
  static getOpenLotAdvanceRequestUrl =
    "api/requestAdvance/GetOpenLotAdvanceRequest";
  static getLotAdvanceUrl = "api/requestAdvance/GetAdvanceDetails";
  static createLotAdvanceUrl = "api/requestAdvance/Create";
  static lotAdvancePaymentRequestUrl = "api/lots/advancepaymentrequest";
  static lotReassayRequest: string = "api/lots/LotReassayRequest";
  static deleteLotUnlockedEstLines =
    "api/lotEstimateLine/DeleteLotUnlockedEstLines";
  static cancelLotUrl = "api/lots/CancelLot";
  static refreshLotForSettlementUrl = "api/lots/refreshLotForSettlement";
  static lockLinesByLotId = "api/lots/lockLinesByLotId";
  static getLockLines = "api/locklines";
  static createLockLine = "api/locklines/Create";
  static enabledLockLine = "api/locklines/Enabled";
  static getDivisions = "api/application/Divisions";
  static getSubDivisions = "api/application/SubDivisions";
  static createAccount = "api/application/CreateAccount";
  static getShortLockLine = "api/locklines/GetShortLockLine";
  static getHasRhodiumPo = "api/locklines/GetHasRhodiumPo";
  static getHasIridiumPo = "api/locklines/GetHasIridiumPo";
  static getHasRutheniumPo = "api/locklines/GetHasRutheniumPo";
  static getGoogleCaptchaKey = "api/application/Config";
  static passwordResetRequestURL: string = "api/application/PasswordResetRequest/";
  static passwordResetToken: string = "fsa89sfff88d1126";

  // all url's string literals     - e n d

  // Lot Statuses
  static lotAdded: string = "Lot Added";
  static materialReceived: string = "Material Received";
  static MeltInProgress: string = "Melt in Progress";
  static movedToMelt: string = "Moved to melt";
  static assayInProgress: string = "Assay in Progress";
  static movedToAssay: string = "Moved to Assay";
  static readyToSettle: string = "Ready to Settle";
  static settled: string = "Settled";
  static cancelled: string = "Cancelled";
  static submittedToSettle: string = "Submit to Settle";
  // Lot Statuses

  // Metals
  static gold: string = "Gold";
  static silver: string = "Silver";
  static platinum: string = "Platinum";
  static palladium: string = "Palladium";
  static rhodium: string = "Rhodium";
  static iridium: string = "Iridium";
  static ruthenium: string = "Ruthenium";
  static goldIso2: string = "1";
  static silverIso2: string = "2";
  static platinumIso2: string = "3";
  static palladiumIso2: string = "4";
  static rhodiumIso2: string = "5";
  static iridiumIso2: string = "6";
  static rutheniumIso2: string = "7";
  // Metals

  // Others
  static lotsSortOrder: string = "datereceived desc";
  static undefined: string = "undefined";
  static currentValue: string = "currentValue";
  static datereceivedDesc: string = "dateReceived desc";
  static datereceived: string = "dateReceived";
  static desc: string = "desc";

  //Advance request messages
  static advanceRequestAlreadyExist: string =
    "Advance payment request already exist.";
  static advanceRequestNotMoreThan90PercentMessage =
    "Cannot process advance payment request for more than 90% of metal value.";
  static enterValueToAdvance = "Please enter a value to advance.";
  static advanceRequestSuccessfullyRaisedMessage =
    "Advance request successfully raised.";

  // set options for every post request in FizTrade.
  static options = new RequestOptions({
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
  });

  static get remoteServiceBaseUrl() {
    if (window.location.href.indexOf("localhost") > 0) {
      return this.apiUrl + "/";
    } else {
      return "/";
    }
    }
}
