﻿import { Injectable } from '@angular/core';

@Injectable()
export class MessageService {
    info(message: string, title?: string): any {
        return dg.message.info(message, title);
    }

    success(message: string, title?: string, content?: any): any {
        return dg.message.success(message, title, content);
    }

    warn(message: string, title?: string): any {
        return dg.message.warn(message, title);
    }

    error(message: string, title?: string): any {
        return dg.message.error(message, title);
    }
}
