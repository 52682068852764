import { Directive, ElementRef, Input } from '@angular/core';
import * as $ from 'jquery';

@Directive({
    selector: '[busyIf]'
})
export class BusyIfDirective {

    constructor(
        public el: ElementRef
    ) {
    }

    @Input()
    set busyIf(loading: any) {
        loading === 'true' ? dg.ui.setBusy($(this.el.nativeElement), { css: { backgroundColor: 'none' } }) : dg.ui.clearBusy($(this.el.nativeElement));
    }
}
