import { Injectable } from "@angular/core";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { AppConsts } from "../../..//shared/AppConsts";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class BadgeService {
  private badgeCount = new Subject<number>();

  badgeCount$ = this.badgeCount.asObservable();

  constructor(private http: Http) {}

  badgeUpdated(value: number) {
    this.badgeCount.next(value);
  }

  getReadyToSettleCount(status: string): Observable<any> {
    var url =
      AppConsts.remoteServiceBaseUrl +
      AppConsts.getReadyToSettleCountUrl +
      "/" +
      status;
    return this.http.get(url).pipe(map((response) => response.json()));
  }
}
