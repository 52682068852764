﻿import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { AppConsts } from '../AppConsts';
import { Observable, of, Subject, throwError } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';


export interface IBidPrice {
    goldBid: number;
    goldChange: number;
    goldChangePercent: number;

    silverBid: number;
    silverChange: number;
    silverChangePercent: number;

    platinumBid: number;
    platinumChange: number;
    platinumChangePercent: number;

    palladiumBid: number;
    palladiumChange: number;
    palladiumChangePercent: number;

    rhodiumBid: number;
    rhodiumChange: number;
    rhodiumChangePercent: number;

    iridiumBid: number;
    iridiumChange: number;
    iridiumChangePercent: number;

    rutheniumBid: number;
    rutheniumChange: number;
    rutheniumChangePercent: number;

    timestamp: Date;
}

export class BidPrice implements IBidPrice {
    goldBid: number;
    goldChange: number;
    goldChangePercent: number;

    silverBid: number;
    silverChange: number;
    silverChangePercent: number;

    platinumBid: number;
    platinumChange: number;
    platinumChangePercent: number;

    palladiumBid: number;
    palladiumChange: number;
    palladiumChangePercent: number;

    rhodiumBid: number;
    rhodiumChange: number;
    rhodiumChangePercent: number;

    iridiumBid: number;
    iridiumChange: number;
    iridiumChangePercent: number;

    rutheniumBid: number;
    rutheniumChange: number;
    rutheniumChangePercent: number;

    timestamp: Date;

    static fromJS(data: any): BidPrice {
        let result = new BidPrice();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.goldBid = data['goldBid'];
            this.goldChange = data['goldChange'];
            this.goldChangePercent = data['goldChangePercent'];

            this.silverBid = data['silverBid'];
            this.silverChange = data['silverChange'];
            this.silverChangePercent = data['silverChangePercent'];

            this.platinumBid = data['platinumBid'];
            this.platinumChange = data['platinumChange'];
            this.platinumChangePercent = data['platinumChangePercent'];

            this.palladiumBid = data['palladiumBid'];
            this.palladiumChange = data['palladiumChange'];
            this.palladiumChangePercent = data['palladiumChangePercent'];

            this.rhodiumBid = data['rhodiumBid'];
            this.rhodiumChange = data['rhodiumChange'];
            this.rhodiumChangePercent = data['rhodiumChangePercent'];

            this.iridiumBid = data['iridiumBid'];
            this.iridiumChange = data['iridiumChange'];
            this.iridiumChangePercent = data['iridiumChangePercent'];

            this.rutheniumBid = data['rutheniumBid'];
            this.rutheniumChange = data['rutheniumChange'];
            this.rutheniumChangePercent = data['rutheniumChangePercent'];

            this.timestamp = data['timestamp'];
        }
    }
}

export interface IBidPriceResultModel {
    data: IBidPrice;
    success: boolean;
    message: string;
}

export class BidPriceResultModel implements IBidPriceResultModel {
    data: IBidPrice;
    success: boolean;
    message: string;
}

export interface IMarketStatus {
    metal: string;
    status: string;
    reason: string;
}

export class MarketStatus implements IMarketStatus {
    metal: string;
    status: string;
    reason: string;

    static fromJS(data: any): MarketStatus {
        let result = new MarketStatus();
        result.init(data);
        return result;
    }

    init(data?: any) {
        if (data) {
            this.metal = data['metal'];
            this.status = data['status'];
            this.reason = data['reason'];
        }
    }
}

@Injectable()
export class BidPriceService {

    private bidPrice: IBidPrice;
    private spotPriceDataSubject: Subject<BidPriceResultModel> = new Subject<BidPriceResultModel>();
    marketOpen: boolean = null;
    marketStatus: string = "open";

    constructor(
        private http: Http
    ) { }

    get spotPriceData(): Observable<BidPriceResultModel> {
        return this.spotPriceDataSubject.asObservable();
    }

    get bidPriceData(): IBidPrice {
        return this.bidPrice;
    }

    getSpotPrices(): Observable<BidPriceResultModel> {
        var url = AppConsts.remoteServiceBaseUrl + AppConsts.getSpotPricesUrl;
        return this.http.get(url)
            .pipe(map(response => {
                return this.processSpotPriceResponse(response.json());
            }),
                catchError(response => {
                    if (response instanceof Response) {
                        return <Observable<BidPriceResultModel>><any>throwError(response);
                    }
                }));
    }

    getSpotPricesForLoggedUser(): Observable<BidPriceResultModel> {
        var url = AppConsts.remoteServiceBaseUrl + AppConsts.getSpotPricesUrlForLoggedUser;
        return this.http.get(url)
            .pipe(map(response => {
                return this.processSpotPriceResponse(response.json());
            }),
                catchError(response => {
                    if (response instanceof Response) {
                        return <Observable<BidPriceResultModel>><any>throwError(response);
                    }
                }));
    }

    getMarketStatus(): Observable<MarketStatus[]> {
        var url = AppConsts.remoteServiceBaseUrl + AppConsts.getMarketStatusUrl;
        return this.http.get(url)
            .pipe(map(response => {
                return response.json();
            }),
                catchError(response => {
                    if (response instanceof Response) {
                        return <Observable<MarketStatus[]>><any>throwError(response);
                    }
                }));
    }

    getMarketStatusForLoggedUser(): Observable<MarketStatus[]> {
        var url = AppConsts.remoteServiceBaseUrl + AppConsts.getMarketStatusUrlForLoggedUser;
        return this.http.get(url)
            .pipe(map(response => {
                return response.json();
            }),
                catchError(response => {
                    if (response instanceof Response) {
                        return <Observable<MarketStatus[]>><any>throwError(response);
                    }
                }));
    }

    protected processSpotPriceResponse(response: IBidPrice): BidPriceResultModel {
        var result = new BidPriceResultModel();
        result.success = true;
        this.bidPrice = BidPrice.fromJS(response);
        result.data = this.bidPrice;
        this.spotPriceDataSubject.next(result);
        return result;
    }

    processMarketStatusData(marketStatus: any): boolean {
        if (marketStatus) {
            this.marketOpen = true;
            for (let metal of marketStatus) {
                if (metal.status.toLowerCase() === 'close' || metal.status.toLowerCase() === 'closed') {
                    this.marketOpen = false;
                }
            }
            this.marketStatus = this.marketOpen ? 'open' : 'closed';
        }
        return this.marketOpen; 
    }
}
