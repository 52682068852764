﻿import { Injectable } from "@angular/core";
import { Http, RequestOptions, ResponseContentType } from "@angular/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppConsts } from "../../../shared/AppConsts";

@Injectable()
export class HomeService {
  constructor(private http: Http) {}

  getSubDivisions(): Observable<any> {
      var url = AppConsts.remoteServiceBaseUrl + AppConsts.getSubDivisions;
      return this.http.get(url)
          .pipe(map(response => response.json()));
  }
  getDivisions(): Observable<any> {
      var url = AppConsts.remoteServiceBaseUrl + AppConsts.getDivisions;
      return this.http.get(url)
          .pipe(map(response => response.json()));
  }
  register(register: any): Observable<any> {
      var url = AppConsts.remoteServiceBaseUrl + AppConsts.createAccount;
      return this.http.post(url, register);

    }
  siteKey(): Observable<any> {
        var url = AppConsts.remoteServiceBaseUrl + AppConsts.getGoogleCaptchaKey;
        return this.http.get(url)
            .pipe(map(response => response.json()));
    }
    passwordResetEmail(emailAddress: string, recaptcha: string): Observable<any> {
        return this.http.post(AppConsts.remoteServiceBaseUrl + AppConsts.passwordResetRequestURL + AppConsts.passwordResetToken,
            { emailAddress: emailAddress, captchaResponse: recaptcha })
            .pipe(map(response => response));
    }
}
