import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { AppLayoutRoutingModule } from "./app-layout-routing.module";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [...AppLayoutRoutingModule.components],
  providers: [],
})
export class AppLayoutModule {}
