﻿import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HomeService } from "./services/home.service";
import { MessageService } from "../../shared/services/message.service";

export interface DialogData {
    siteKey: string;
}

@Component({
    selector: 'dialog-content-example',
    templateUrl: 'ForgetPasswordTemplate.html',
    styleUrls: ["./home.component.css"],
    providers: [HomeService],
})
export class DialogContentForgetPasswordDialog {

    public passwordResetForm: FormGroup;
    public siteKey: string;

    constructor(
        public dialogRef: MatDialogRef<DialogContentForgetPasswordDialog>,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        private messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    ngOnInit() {
        this.passwordResetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern('^\\S+@\\S+\\.\\S+$')]],
            recaptcha: [''],
        });
        this.siteKey = this.data.siteKey;
    }

    SendPasswordResetEmail(form: FormGroup): void {
        var ref = this.dialogRef;
        var resMessage;
        if (form.value != undefined && form.value.email != '') {
            dg.ui.setBusy();
            this.homeService.passwordResetEmail(form.value.email, form.value.recaptcha).subscribe((res: any) => {
                this.passwordResetForm.patchValue({ captcha: '' });
                ref.close();
                dg.ui.clearBusy();
                if (res._body == "ok") {
                    resMessage = "Password reset email sent to " + form.value.email;
                    return this.messageService.success(
                        resMessage
                    );
                }
                else {
                    if (res._body === 'Error') {
                        resMessage = "An Error Occured While Sending Forget Password Mail";
                        return this.messageService.error(
                            resMessage
                        );
                    }
                    else if (res._body === 'Invalid Captcha') {
                        resMessage = "Invalid Captcha";
                        return this.messageService.error(
                            resMessage
                        );
                    }
                }
            }, (error: any) => {
                this.passwordResetForm.patchValue({ captcha: '' });
                dg.ui.clearBusy();
            });
        }
    }

    captchaState(): boolean {
        return this.passwordResetForm.get('recaptcha').value && this.passwordResetForm.get('recaptcha').value.length > 0;
    }
    handleCaptchaSuccess(captchaResponse: string): void {
        this.passwordResetForm.patchValue({ recaptcha: captchaResponse });
    }
    handleCaptchaClearOnReset(): void {
        this.passwordResetForm.patchValue({ recaptcha: '' });
    }
    handleCaptchaClearOnExpire(): void {
        this.passwordResetForm.patchValue({ recaptcha: '' });
    }
}