import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppLayoutComponent } from "./app-layout.component";
import { NavigationComponent } from "./navigation/navigation.component";
import { ProductSearchComponent } from "./navigation/product-search/product-search.component";

const APP_LAYOUT_ROUTES: Routes = [
  {
    path: "",
    component: AppLayoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(APP_LAYOUT_ROUTES)],
  exports: [RouterModule],
})
export class AppLayoutRoutingModule {
  static components: any[] = [
    AppLayoutComponent,
    NavigationComponent,
    ProductSearchComponent,
  ];
}
