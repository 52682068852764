import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { UtilsService } from './utils.service';

import { AppConsts } from '../AppConsts';

@Injectable()
export class TokenService {

    constructor(private localStorageService: LocalStorageService,
        private utilsService: UtilsService) {

    }

    getToken(): string {
        var token = this.utilsService.getCookieValue(AppConsts.authorization.encrptedAuthTokenName);
        return token;        
    }

    getTokenCookieName(): string {
        return AppConsts.tokenName;
    }

    clearToken(): void {
        this.localStorageService.clear();

        this.utilsService.setCookieValue(
            AppConsts.authorization.encrptedAuthTokenName,
            null,
            new Date("1/1/1970"),
            AppConsts.cookiePath);
    }

    setToken(authToken: string, expireDate?: Date): void {
        this.localStorageService.store(AppConsts.tokenName, authToken);

        this.utilsService.setCookieValue(
            AppConsts.authorization.encrptedAuthTokenName,
            authToken,
            expireDate,
            AppConsts.cookiePath
        );
    }
}
