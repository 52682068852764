import { Component, Inject, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { TokenService } from "../../shared/services/token.service";
import {
  AuthenticateModel,
  AuthenticateResultModel,
  TokenAuthServiceProxy,
} from "../../shared/services/service-proxies/service-proxies";
import { NavigationService } from "../../shared/services/navigation.service";
import { AppSessionService } from "../../shared/services/app-session.service";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DialogContentExampleDialog } from './home.newaccount.component';
import { HomeService } from "./services/home.service";
import { ISubDivision, IDivision } from "../../shared/models";
import { DialogContentForgetPasswordDialog } from './home.forgetPassword.component';
import swal from "sweetalert";

@Component({
  selector: "dg-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [HomeService],
})
export class HomeComponent implements OnInit {
  authenticateModel: AuthenticateModel;
  authenticateResult: AuthenticateResultModel;

  submitting: boolean = false;
  public showErrorMessage: boolean;
  public errorMessage: string;
  public subDivisions: ISubDivision[];
  public  divisions: IDivision[];
  public animal: string;
  public name: string;
  public siteKey: string;

  constructor(
    private tokenAuthService: TokenAuthServiceProxy,
    private tokenService: TokenService,
    private navigationService: NavigationService,
    private appSessionService: AppSessionService,
    private homeService: HomeService,
    public dialog: MatDialog
  ) {
      this.clear();
  }

  ngOnInit() {
      this.homeService.getSubDivisions().subscribe(
          (res: any) => {
              this.subDivisions = res;
          });
      this.homeService.getDivisions().subscribe(
          (res: any) => {
              this.divisions = res;
          });
      this.homeService.siteKey().subscribe(
          (res: any) => {
          this.siteKey = res.googleCaptchaKey;
      });
  }

  toggleSignInBox(e: any) {
    $("#divSignInPopDown").fadeToggle();
    e.stopPropagation();
  }

  onClickedOutside(e: any) {
    if (screen.width < 768) {
      $("#divSignInPopDown").fadeOut();
    }
  }



    openDialog(): void {
      window.scrollTo(0, 0);
      const dialogRef = this.dialog.open(DialogContentExampleDialog, {
          width: '60em',
          data: { country: 'USA', divisions: this.divisions, subDivisions: this.subDivisions, siteKey: this.siteKey}
  });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
    openPasswordResetEmailDialog(): void {
        window.scrollTo(0, 0);
        const dialogReference = this.dialog.open(DialogContentForgetPasswordDialog, {
            width: '40em', height: '25em' ,
            data: { siteKey: this.siteKey }
        });
        dialogReference.afterClosed().subscribe(result => {

        });
    }
    ShowMessage(): void {
        swal({
            title: "Call Us",
            text: `Please call 888-878-8100 and select option 4 for assistance with your account.`,
        });
        $('.swal-title').css('text-align', 'left');
    }

  navigateToDashBoard() {
    this.navigationService.navigateTo("/dashboard");
  }

    login(): void {
    if (this.submitting) {
      return;
    }

    dg.ui.setBusy();

    this.submitting = true;
    this.clearErrorMessage();

    this.tokenAuthService
      .authenticate(this.authenticateModel)
      .pipe(
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe(
        (result: AuthenticateResultModel) => {
          this.processAuthenticateResult(result);
          dg.ui.clearBusy();
        },
        (error: any) => {
          this.showErrorMessage = true;
          this.errorMessage = error.message;
          dg.ui.clearBusy();
        }
      );
  }

  private processAuthenticateResult(
    authenticateResult: AuthenticateResultModel
  ) {
    this.authenticateResult = authenticateResult;

    if (authenticateResult.encryptedAuthToken) {
      // successfully logged in
      this.doLogin(authenticateResult);
    } else {
      this.navigationService.navigateTo("/");
    }
  }

  private doLogin(authenticateResult: AuthenticateResultModel): void {
    var tokenExpireDate = new Date(
      new Date().getTime() + 1000 * +authenticateResult.expirationTime
    );

    this.appSessionService.setUser(authenticateResult);
    this.tokenService.setToken(
      authenticateResult.encryptedAuthToken,
      tokenExpireDate
    );
    this.navigationService.navigateTo("/dashboard");
  }

  private clear(): void {
    this.authenticateModel = new AuthenticateModel();
    this.authenticateResult = null;
  }

  private clearErrorMessage(): void {
    this.errorMessage = "";
    this.showErrorMessage = false;
    this.authenticateResult = null;
  }
}
