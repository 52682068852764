import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    private storage: any;

    constructor() {
        this.storage = localStorage;
    }

    // Method to clear all data from localstorage/cookie,
    // When localstorage is not accessible, cookie will be used.
    public clear() {
        if (dg.utils.isLocalStorageAccessible()) {
            this.storage.clear();
        } else {
            var cookies = document.cookie.split(";");

            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        }
    }

    public retrieve(key: string): any {
        if (dg.utils.isLocalStorageAccessible()) {
            const item = this.storage.getItem(key);

            if (item && item !== 'undefined') {
                return JSON.parse(this.storage.getItem(key));
            }
        } else {
            const item = dg.utils.getCookieValue(key);
            if (item && item !== 'undefined') {
                return JSON.parse(this.storage.getItem(key));
            }
        }


        return;
    }

    public store(key: string, value: any) {
        if (dg.utils.isLocalStorageAccessible()) {
            this.storage.setItem(key, JSON.stringify(value));
        } else {
            dg.utils.setCookieValue(key, value);
        }
    }
}
