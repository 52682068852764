import { Pipe, PipeTransform } from '@angular/core';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'fxIf' })
export class FxIfPipe implements PipeTransform {

    constructor(private media: ObservableMedia) { }

    transform(data: Array<'xs' | 'sm' | 'md' | 'lg' | 'xl'>): Observable<boolean> {
        //return this.media.asObservable().map((change: MediaChange) => {
        //    if (!data) {
        //        return false;
        //    }
        //    const mqAlias: any = change.mqAlias;
        //    return data.includes(mqAlias);
        //});

        return this.media.asObservable().pipe(map(change => {
            if (!data) {
                return false;
            }
            const mqAlias: any = change.mqAlias;
            return data.includes(mqAlias);
        }));
    }
}