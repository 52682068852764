﻿import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ExternalLoginService } from "./external-login.service";
import { TokenService } from "../../shared/services/token.service";
import { AppConsts } from "../../shared/AppConsts";

@Component({
  moduleId: module.id,
  selector: "external-login",
  templateUrl: "./external-login.component.html",
})
export class ExternalLoginComponent implements OnInit {
  private authToken: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private externalLoginService: ExternalLoginService,
    private tokenService: TokenService
  ) {
    dg.ui.setBusy();
  }

  ngOnInit() {
    this.externalLoginService.showSpotPrice(false);

    this.route.queryParams.subscribe((params) => {
      this.authToken = params["token"] || "";
    });

    if (!this.authToken) {
      this.navigateToHomePage();
    } else {
      this.validateExternalLogin(this.authToken);
    }
  }

  validateExternalLogin(authToken: string): void {
    this.externalLoginService
      .validateUser(authToken)
      .subscribe((result: any) => {
        if (result && result.success) {
          var tokenExpireDate = new Date(
            new Date().getTime() + 1000 * +AppConsts.cookieExpirationTime
          );
          this.tokenService.setToken(
            result.encryptedAuthToken,
            tokenExpireDate
          );
          window.location.href = "/dashboard";
        } else {
          this.navigateToHomePage();
        }
      });
  }

  navigateToHomePage(): void {
    dg.ui.clearBusy();
    this.router.navigate([""]);
    this.externalLoginService.showSpotPrice(true);
  }
}
