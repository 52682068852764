import { NgModule } from "@angular/core";
import { HttpModule } from "@angular/http";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputTextareaModule } from "primeng/inputtextarea";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppLayoutModule } from "./modules/app-layout/app-layout.module";
import { BidPriceModule } from "./modules/bid-price/bid-price.module";
import { ExternalLoginModule } from "./modules/external-login/external-login.module";
import { HomeModule } from "./modules/home/home.module";
import { ServiceProxyModule } from "./shared/services/service-proxies/service-proxy.module";
import { SharedModule } from "./shared/shared.module";
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { DialogContentExampleDialog } from './modules/home/home.newaccount.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DialogContentForgetPasswordDialog } from "./modules/home/home.forgetPassword.component";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    BrowserAnimationsModule,
    SharedModule.forRoot(),
    HttpModule,
    // Only module that app module loads
    BidPriceModule,
    ServiceProxyModule,
    ExternalLoginModule,
    AppLayoutModule,
    HomeModule,
    InputTextareaModule,
    AppRoutingModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgxMaskModule.forRoot(),
  ],
    declarations: [AppComponent, DialogContentExampleDialog, DialogContentForgetPasswordDialog],
    entryComponents: [DialogContentExampleDialog, DialogContentForgetPasswordDialog],
  providers: [{
      provide: MatDialogRef,
      useValue: {}
  },],
  bootstrap: [AppComponent],
})
export class AppModule {}
