import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { SharedModule } from "../../shared/shared.module";
import { HomeComponent } from "./home.component";


@NgModule({
  imports: [BrowserModule, SharedModule],
  declarations: [HomeComponent],
})
export class HomeModule {}
