﻿import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { ExternalLoginComponent } from "./external-login.component";
import { ExternalLoginService } from "./external-login.service";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [ExternalLoginComponent],
  providers: [ExternalLoginService],
})
export class ExternalLoginModule {}
