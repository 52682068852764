﻿import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Type, CompilerOptions, NgModuleRef } from '@angular/core';
import { AppConsts } from './shared/AppConsts';

export class AppPreBootstrap {

    static run(callback: () => void): void {
        AppPreBootstrap.getAppConfig(() => {
            AppPreBootstrap.getApplicationConfig(callback);
        });
    }

    static bootstrap<TM>(moduleType: Type<TM>, compilerOptions?: CompilerOptions | CompilerOptions[]): Promise<NgModuleRef<TM>> {
        return platformBrowserDynamic().bootstrapModule(moduleType, compilerOptions);
    }


    private static getAppConfig(callback: () => void) {
        return dg.ajax({
            url: '/assets/appconfig.json',
            method: 'GET'
        }).done(result => {
            AppConsts.appBaseUrl = result.appBaseUrl;
            AppConsts.apiUrl = result.remoteServiceBaseUrl;
            callback();
        });
    }

    private static getApplicationConfig(callback: () => void) {
        return dg.ajax({
            url: AppConsts.remoteServiceBaseUrl + 'api/application/config',
            method: 'GET'
        }).done(response => {
            if (response.success) {
                AppConsts.cookieDomain = response.result.cookieDomain;
                AppConsts.cookieExpirationTime = response.result.cookieExpirationTime;
                AppConsts.copyrightYear = response.result.copyrightYear;
                AppConsts.iraConnectHost = response.result.iraConnectHost;
                AppConsts.fizTradeHost = response.result.fizTradeHost;
                AppConsts.redesignFiztradeHost = response.result.redesignFizTradeHost;
                AppConsts.customerCareNumber = response.result.customerCareNumber;
                AppConsts.technicalSupportNumber = response.result.technicalSupportNumber;
                AppConsts.refinerySupportNumber = response.result.refinerySupportNumber;
            }
            callback();
        });
    }
}
