﻿import { Injectable } from "@angular/core";
import { Http, Response } from "@angular/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { AppConsts } from "../../shared/AppConsts";

@Injectable()
export class ExternalLoginService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(private http: Http) {}

  validateUser(authToken: string): Observable<any> {
    if (!authToken) {
      return of<any>(null);
    }

    let tokenString: {} = {
      authToken: authToken,
    };

    return this.http
      .post(
        AppConsts.remoteServiceBaseUrl + AppConsts.externalLoginUrl,
        tokenString,
        AppConsts.options
      )
      .pipe(
        mergeMap((response: Response) => {
          return of<any>(response.json());
        })
      );
  }

  showSpotPrice(value: boolean) {
    this.status.next(value);
  }
}
