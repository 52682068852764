import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { BidPriceComponent } from "./bid-price.component";

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [BidPriceComponent],
  exports: [BidPriceComponent],
})
export class BidPriceModule {}
