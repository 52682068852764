import { Injectable } from '@angular/core';
import { AppConsts } from '../AppConsts';
import { TokenService } from './token.service';

@Injectable()
export class AppAuthService {

  constructor(private tokenService: TokenService) { }

  logout(reload?: boolean): void {
    this.tokenService.clearToken();
    if (reload !== false) {
      location.href = '/';
    }
  }
}
